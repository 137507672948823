<template>
  <div class="page-height">
    <div class="flex-center collection-top">
      <div class="w1200">
       <div class="market-top-cont">
         <div class="flex-between">
           <div class="page-title">采集交换</div>
         </div>
         <div class="box-info">生猪大数据数智云脑支持从文件、数据库、API接口、爬虫等多种数据源中自动或手动地获取数据，并进行数据加工，将数据转化为可用于分析、处理或存储的格式。生猪数据的采集是为生猪的法模型提供可靠的养料，根据用户需求，开放给个企业、个人使用，为生猪从业者提供不可或缺的数据支撑。</div>
        <div class="collection-option">
          <div class="collection-option-item">
            <img src="../assets/img/collection/option1.png" alt="">
            <div>文件</div>
          </div>
          <div class="collection-option-item">
          <img src="../assets/img/collection/option2.png" alt="">
            <div>数据库</div>
          </div>
          <div class="collection-option-item">
          <img src="../assets/img/collection/option3.png" alt="">
            <div>接口</div>
          </div>
          <div class="collection-option-item">
          <img src="../assets/img/collection/option4.png" alt="">
            <div>爬虫</div>
          </div>
        </div>
       </div>
      </div>
    </div>
    <div class="flex-center grey-box">
      <div class="w1200 ">
        <div class="home-title" style="margin-bottom: 10px">
          <img alt="" src="../assets/img/home/title-l.png">
          <div>采集案例</div>
          <img alt="" src="../assets/img/home/title-r.png">
        </div>
        <div class="box-info t-c" style="margin-bottom:50px">生猪大数据数据仓通过多个渠道采集数据</div>
        <div class="flex-between">
          <div class="collection-case-left">
            <div class="collection-case-line"><div class="dot"></div>生猪养殖户通过“智慧养殖”上传生猪养殖数据，包括猪崽数量，生猪出栏数量等；</div>
            <div class="collection-case-line"><div class="dot"></div>监管部门通过“粪污系统”提供场所消杀情况及粪污处理情况等；</div>
            <div class="collection-case-line"><div class="dot"></div>市场监管局及生猪市场交易人员通过“阳光码”追踪生猪源，了解生猪价格等；</div>
            <div class="collection-case-line"><div class="dot"></div>地方政府通过“荣易坊”获取地区生猪健康状况等。</div>
          </div>
          <img class="collection-case-right" src="../assets/img/collection/collection-case.png" alt="">
        </div>
      </div>
    </div>
    <div class="flex-center blue-box">
      <div class="w1200 ">
        <div class="home-title">
          <img alt="" src="../assets/img/home/title-l.png">
          <div>交换数据</div>
          <img alt="" src="../assets/img/home/title-r.png">
        </div>
        <div class="box-info t-c">生猪大数据智云脑向多个角色交换数据</div>
        <div class="flex-between exchange-cont">
          <div class="exchange-left">
            <img src="../assets/img/collection/exchange-img.png" alt="">
            <div class="t1">生猪大数据 智云脑</div>
          </div>
          <div class="real-time-right">
            <div class="real-time-data">
              拥有算法 <span>181</span> 例
            </div>
            <div class="real-time-data">
              共享次数 <span>8,934</span> 次
            </div>
            <div class="real-time-data">
              帮助个人 <span>1,734</span> 人
            </div>
            <div class="real-time-data">
              助力企业 <span>2,388</span> 家
            </div>
            <div class="real-time-data">
              接入平台 <span>29</span> 个
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FdoStatistic} from "@/assets/js/api";

export default {
  name: "Collection",
  data() {
    return {
      newDate:{}
    };
  },
  mounted() {
    this.initData()

  },
  methods:{
    initData(){
      FdoStatistic().then(res => {
        this.newDate = res.data
      })
    }

  }
}
</script>

<style scoped>
@import "../assets/css/home.css";
@import "../assets/css/collection.css";
</style>
